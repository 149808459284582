import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Paper,
  Stack,
  IconButton,
  Icon,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Slide
} from "@mui/material";
import Loading from "components/Loading";
import ButtonIcon from "components/ui/Buttons/ButtonIcon";
import TableDataGrid from "components/ui/Tables/TableDataGrid";
import {
  showAlert,
  showAlertConfirm,
  showAlertError,
  showAlertSuccess,
} from "utils/alert";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import userService from "services/user.services";
import HeaderPage from "components/HeaderPage";
import Title from "components/Title";
import estudianteService from "services/estudiantes.services";

const headers = [
  {
    field: "cod_estudiante",
    headerName: "Codigo",
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    //width: 100,
    // hide: true,
    flex: 1,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    //description: 'This column has a value getter and is not sortable.',
    flex: 2,
    minWidth: 100,
  },
  {
    field: "num_doc",
    headerName: "Número Documento",
    //description: 'This column has a value getter and is not sortable.',
    flex: 1,
    minWidth: 100,
  },
  {
    field: "id_ie",
    headerName: "Colegio",
    //description: 'This column has a value getter and is not sortable.',
    flex: 1,
    minWidth: 100,
  },
  {
    field: "fec_nac",
    headerName: "Fecha Nacimiento",
    //description: 'This column has a value getter and is not sortable.',
    flex: 1,
    minWidth: 100,
  }

];
const initialValues = {
  colegio: "",
  grado: "",
  grupo: ""
};
const formSchema = Yup.object().shape({
  colegio: Yup.string().required("Campo Requerido"),
  grado: Yup.string().required("Campo Requerido"),
  grupo: Yup.string().required("Campo Requerido")
});

const ListEstudiantes = () => {
  const [openLoading, setOpenLoading] = useState(false);
  const [columns, setColumns] = useState(headers);
  const [estudiantes, setEstudiantes] = useState([]);
  const [mostrarRI, setMostrarRI] = useState(false);
  const [data, setData] = useState();
  const theme = useTheme();
  async function getEstudiantes() {
    try {
      setOpenLoading(true);
      const data = {
        prueba: -1
      }
      var response = await estudianteService.obetenerEstudiantes(data);
      if (response.status === 200) {
        setOpenLoading(false);
        console.log(response.data.data);
        setEstudiantes(response.data.data);
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');
      setOpenLoading(true);
    }
  }
  const init = () => {
    getEstudiantes();
    // setUsers([]);
    // getUsers(users);
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Loading openLoading={openLoading} />
      <Title title="Dashboard - Listado de estudiantes" />
      <HeaderPage descripcion={"Estudiantes"} />
      <Paper
        elevation={5}
        sx={{
          margin: "1em",
          minHeight: "86vh",
          //minWidth: "85vh",
        }}
      >
        {/* Page */}
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {

          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            setFieldValue,
            /* and other goodies */
          }) => (
            <Box
              component="form"
              noValidate
              id="asignar-pruebas-form"
              sx={{ mt: 1 }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  padding: "1em",
                }}
              >
                {/* Usuarios */}
                {estudiantes.length > 0 && (
                  <Grid item xs={12}>
                    <TableDataGrid
                      tittle={"Listado de estudiantes"}
                      data={estudiantes}
                      columns={columns}
                      filtersText={["nombre", "cod_estudiante"]}
                      LabelFiltersText="Filtrar por codigo o nombre"
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
        </Formik>
      </Paper>
    </>
  );
};

export default ListEstudiantes;
