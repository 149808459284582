import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";

const TableDataGrid = ({
  tittle,
  data,
  columns,
  selection,
  setData,
  filtersText,
  filterEncuestador,
  LabelFiltersText
}) => {
  const [filterTable, setFilterTable] = useState("");
  const [filterData, setFilterData] = useState(data);
  const theme = useTheme();

  function aplyFilter(item) {
    let existe = false;
    filtersText.forEach(function (numero) {
      if (item[numero].toUpperCase().includes(filterTable)) {
        existe = true;
        return true;
      }
    });
    return existe;
  }
  function filterTextBox(item) {
    if (filterTable === "" || filterTable === undefined) {
      return true;
    } else {
      return aplyFilter(item);
    }
  }

  function filterExcluyente(item) {
    if (filterEncuestador === false || filterEncuestador === undefined) {
      return true;
    } else {
      return item.visibleHabilitar === !filterEncuestador;
    }
  }
  useEffect(() => {
    if (filterTextBox) {
      setFilterData(
        data.filter((item) => filterTextBox(item) && filterExcluyente(item))
      );
    }
  }, [filterTable, filterEncuestador]);
  useEffect(() => {
    setFilterData(data);
  }, [data]);
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          borderRadius: 1,
        }}
      >
        <Grid item xs={12} sx={{ pl: "1em" }}>
          <Typography variant="h6" color="text.primary">
            {tittle}
          </Typography>
        </Grid>
        {filtersText && (
          <Grid item xs={6} sx={{ pl: "1em" }}>
            <TextField
              fullWidth
              id="filterTable"
              name="filterTable"
              value={filterTable}
              onChange={(e) => setFilterTable(e.target.value.toUpperCase())}
              label={LabelFiltersText}
              autoFocus
              variant="standard"
            />
          </Grid>
        )}
        <Grid item xs={12} sx={{ padding: "1em" }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.secondary.contrastText,
                fontSize: 16,
              },
            }}
            rows={filterData}
            columns={columns}
            autoHeight={true}
            pageSize={10}
            checkboxSelection={selection}
            //initialState={{
            //pagination: { paginationModel: { pageSize: 5 } },
            //}}
            rowsPerPageOptions={[10, 20]}
            onSelectionModelChange={(ids) => {
              if (setData) {
                setData(ids);
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TableDataGrid;
