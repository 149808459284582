import React from "react";
import { Typography, CardMedia, Alert, Tooltip, Divider } from "@mui/material";
import { config } from "utils/config";
import AudioPlayer from "./Opciones/AudioPlayer";
// Icons
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

const server = config.API_RESOURCE_URL;

const cardImage = {
  width: "auto",
  height: "auto",
  maxHeight: "100%",
  maxWidth: "100%",
  padding: "0.5em",
};

const LabelPreguntaRenderPregunta = (props, index) => {
  return <Typography
    variant="h6"
    gutterBottom
    sx={{ color: "primary.dark", fontSize: "15px" }}
  >
    {(props.index + 1)}. {props.nombre}
  </Typography>
}
const LabelRespuestaObtenidaRenderPregunta = () => {
  return <>
    <Divider />
    <Typography variant="subtitle2" component="div" sx={{ marginTop: "4px" }}>
      Respuesta del evaluado
    </Typography>
  </>
}

const RenderItemPreguntas = (props) => {
  return <>
    {
      (props.respuestas.length > 0) ? (
        props.respuestas.map((element, index) => (
          <ItemRenderPregunta
            tipo={element.tipo}
            respuesta={element}
          />
        ))
      ) : (
        <>
          <Alert severity="warning">No existen respuestas registradas.</Alert>
        </>
      )
    }
  </>
}

const ItemRenderPregunta = (props, index) => {
  switch (props.tipo) {
    case "texto":
      return (
        <>
          <Typography variant="h6" component="div">
            {props.respuesta.nombre}
          </Typography>
        </>
      );
    case "audio":
      return (
        <>
          <AudioPlayer
            url={props.respuesta.file}
          />
          <Tooltip title="Respuesta generada por el sistema">
            <Typography variant="h6" display="block" gutterBottom>
              <QuestionAnswerIcon fontSize="small" sx={{ "p": 0, "m": 0, "marginRight": "6px" }} />
              {props.respuesta.respuesta.automatica}
            </Typography>
          </Tooltip>
        </>
      );
    case "pizarra":
      return (
        <>
          <CardMedia
            component="img"
            src={props.respuesta.file}
            alt="Imagen capturada"
          />
        </>
      );
    case "input_numerico":
      return (
        <>
          <AudioPlayer
            url={props.respuesta.file}
          />
        </>
      );
    case "pizarra_seleccionable":
      return (
        <>
          <CardMedia
            style={cardImage}
            component="img"
            src={server + props.respuesta.nombre}
            alt="Imagen capturada"
          />
        </>
      );
    default:
      return props.tipo;
  }
}

const CalificarRenderPregunta = (props, index) => {
  switch (props.tipo) {
    case "audio":
      return (
        <>
          <LabelPreguntaRenderPregunta
            index={props.index}
            nombre={props.nombre}
          />
          {props.otros.transcripcion && (
            <>
              <Typography variant="h3" component="div" sx={{ fontSize: "15px" }}>
                Transcripción de Audio
              </Typography>
              <Typography variant="subtitle1" component="div" sx={{ fontSize: "12px", pb: "1em" }}>
                Transcripción de Audio
              </Typography>
            </>
          )}
          <AudioPlayer
            url={server + props.recurso}
          />
          <LabelRespuestaObtenidaRenderPregunta />
          <RenderItemPreguntas
            respuestas={props.respuesta}
          />
        </>
      );
    case "video":
      return (
        <>
          <LabelPreguntaRenderPregunta
            index={props.index}
            nombre={props.nombre}
          />
          <CardMedia
            style={cardImage}
            component="video"
            src={server + props.recurso}
            controls
          />
          <LabelRespuestaObtenidaRenderPregunta />
          <RenderItemPreguntas
            respuestas={props.respuesta}
          />
        </>
      );
    case "texto":
      return (
        <>
          <LabelPreguntaRenderPregunta
            index={props.index}
            nombre={props.nombre}
          />
          <LabelRespuestaObtenidaRenderPregunta />
          <RenderItemPreguntas
            respuestas={props.respuesta}
          />
        </>
      );
    default:
      return props.tipo;
  }
};
export default CalificarRenderPregunta;